import {
  SET_QUEUES,
  ADD_OLD_ATTEMPTS,
  SET_OLD_ATTEMPTS,
} from "../actions/types";

const getCursorFromAttemptsPageUrl = url => {
  if (url) {
    // Don't URL-decode the cursor. I need to place it right back into a URL
    // later.
    const urlParams = new window.URLSearchParams(url.split("?")[1]);
    return urlParams.get("cursor");
  } else {
    return null;
  }
};

const queuesReducer = (state = null, action) => {
  switch (action.type) {
    case SET_QUEUES:
      // Extract the id key from the items and set as key of the resulting object.
      // This allows for faster access later.
      return action.payload.reduce(
        (acc, item) => ({ ...acc, [item["id"]]: item }),
        {}
      );
    case ADD_OLD_ATTEMPTS:
      return {
        ...state,
        [action.payload.queueId]: {
          ...state[action.payload.queueId],
          oldAttempts: [
            ...(state[action.payload.queueId].oldAttempts || []),
            ...action.payload.response.results,
          ],
          oldAttemptsNextCursor: getCursorFromAttemptsPageUrl(
            action.payload.response.next
          ),
        },
      };
    case SET_OLD_ATTEMPTS:
      return {
        ...state,
        [action.payload.queueId]: {
          ...state[action.payload.queueId],
          oldAttempts: action.payload.response.results,
          oldAttemptsNextCursor: getCursorFromAttemptsPageUrl(
            action.payload.response.next
          ),
        },
      };
    default:
      return state;
  }
};

export default queuesReducer;
