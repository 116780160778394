import { useEffect, useRef } from "react";
import _ from "lodash";

export const getQueuedBranchFromQueueDetails = (
  queueDetails,
  branchName,
  excludedStatuses = ["REMOVED", "FAILED"]
) => {
  if (!queueDetails || !branchName) {
    return null;
  }
  const excludedStatusesSet = new Set(excludedStatuses);

  return _.find(
    queueDetails.queued_branches,
    b => b.name === branchName && !excludedStatusesSet.has(b.status)
  );
};

export const useInterval = (callback, delay) => {
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
