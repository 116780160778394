import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@atlaskit/button";
import AddIcon from "@atlaskit/icon/glyph/add";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import Spinner from "@atlaskit/spinner";

import {
  getRepository,
  addBranchToQueue,
  fetchBranchStatus,
  removeBranchFromQueue,
  setRefreshing,
} from "../actions";
import { getQueuedBranchFromQueueDetails } from "../utils";
import RepoConfigError from "./RepoConfigError";
import "./PrOverviewPanel.css";

const EmptyState = () => {
  return <div>Mergetastic has not been enabled for this repo.</div>;
};

const WaitingState = () => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const NoQueueSetup = () => {
  return <div>No queue has been setup for this branch.</div>;
};

const AddToMergeQueue = props => {
  const onClickHandler = (e, analyticsEvent) => {
    props.addBranchToQueue(props.queryParams, props.queue.id);
  };

  return (
    <div>
      <LoadingButton
        appearance="primary"
        iconBefore={<AddIcon label="" size="small" />}
        onClick={onClickHandler}
        isLoading={props.refreshing}
        testId="add-to-queue-button"
      >
        Add to merge queue
      </LoadingButton>
    </div>
  );
};

const RemoveFromMergeQueue = props => {
  const onClickHandler = (e, analyticsEvent) => {
    props.removeBranchFromQueue(props.queryParams, props.queuedBranch.id);
  };

  return (
    <div className="remove-from-queue">
      <LoadingButton
        onClick={onClickHandler}
        iconBefore={<TrashIcon label="" size="small" />}
        isLoading={props.refreshing}
        testId="remove-from-queue-button"
      >
        Remove from merge queue
      </LoadingButton>
    </div>
  );
};

const PrOverviewPanel = ({
  repository,
  queue,
  refreshing,
  getRepository,
  addBranchToQueue,
  fetchBranchStatus,
  removeBranchFromQueue,
  setRefreshing,
}) => {
  const queryParams = new window.URLSearchParams(
    window.decodeURI(useLocation().search)
  );
  const queuedBranch = getQueuedBranchFromQueueDetails(
    queue,
    queryParams.get("source_branch")
  );

  useEffect(() => {
    getRepository(queryParams);
  }, [getRepository]);

  useEffect(() => {
    if (repository) {
      fetchBranchStatus(queryParams);
    }
  }, [repository, fetchBranchStatus]);

  // render the correct component based on the state
  if (refreshing === true) {
    return <WaitingState />;
  } else if (repository === null) {
    // Mergetastic is not enabled for this repo
    return <EmptyState />;
  } else if (repository && repository.config && repository.config.error) {
    return <RepoConfigError repository={repository} hideDetails />;
  } else if (queue === null) {
    // Mergetastic is enabled but no queue has been setup for this target
    // branch.
    return <NoQueueSetup />;
  } else if (queuedBranch) {
    // There is a queue for this target branch and this source branch is already
    // queued.
    return (
      <RemoveFromMergeQueue
        queryParams={queryParams}
        queuedBranch={queuedBranch}
        removeBranchFromQueue={removeBranchFromQueue}
        refreshing={refreshing}
      />
    );
  } else {
    // There is a queue for this target branch and this source branch has
    // not been queued yet.
    return (
      <AddToMergeQueue
        addBranchToQueue={addBranchToQueue}
        queue={queue}
        refreshing={refreshing}
        queryParams={queryParams}
      />
    );
  }
};

const mapStateToProps = state => {
  return {
    repository: state.repository,
    queue: state.queue,
    refreshing: state.refreshing,
  };
};

export default connect(mapStateToProps, {
  getRepository,
  addBranchToQueue,
  fetchBranchStatus,
  removeBranchFromQueue,
  setRefreshing,
})(PrOverviewPanel);
