import dedent from "dedent-js";

export const getRepository = (queryParams, onSuccess, onError) => {
  const account = queryParams.get("account_uuid");
  const repo = queryParams.get("repo_uuid");

  window.AP.require("proxyRequest", request => {
    request({
      url: dedent`/bitbucket/repositories/${account}/${repo}`,
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess(null);
        } else {
          onError();
        }
      },
    });
  });
};

export const createRepository = (queryParams, onSuccess, onError) => {
  const account = queryParams.get("account_uuid");
  const repo = queryParams.get("repo_uuid");

  window.AP.require("proxyRequest", request => {
    request({
      url: dedent`/bitbucket/repositories/${account}/${repo}`,
      type: "POST",
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        onError();
      },
    });
  });
};

export const deleteRepository = (queryParams, onSuccess, onError) => {
  const account = queryParams.get("account_uuid");
  const repo = queryParams.get("repo_uuid");

  window.AP.require("proxyRequest", request => {
    request({
      url: dedent`/bitbucket/repositories/${account}/${repo}`,
      type: "DELETE",
      success: body => {
        onSuccess();
      },
      error: (summary, response) => {
        onError();
      },
    });
  });
};

export const getQueueForTargetBranch = (queryParams, onSuccess, onError) => {
  const repo = queryParams.get("repo_uuid");
  const targetBranch = encodeURIComponent(queryParams.get("target_branch"));

  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/queues/${repo}/${targetBranch}`,
      success: body => {
        onSuccess(body);
      },
      onError: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess(null);
        } else {
          onError();
        }
      },
    });
  });
};

export const getAttemptForSourceBranch = (queryParams, onSuccess, onError) => {
  const repo = queryParams.get("repo_uuid");
  const targetBranch = queryParams.get("target_branch");
  const sourceBranch = queryParams.get("source_branch");

  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/attempts/${repo}/${targetBranch}/${sourceBranch}`,
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess(null);
        } else {
          onError();
        }
      },
    });
  });
};

export const addBranchToQueue = (queryParams, queue, onSuccess, onError) => {
  const sourceBranch = queryParams.get("source_branch");
  const prId = queryParams.get("pr_id");

  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/queued-branches/`,
      type: "POST",
      data: JSON.stringify({
        queue,
        name: sourceBranch,
        pr_id: prId,
      }),
      contentType: "application/json",
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess(null);
        } else {
          onError();
        }
      },
    });
  });
};

export const removeBranchFromQueue = (
  queryParams,
  queuedBranch,
  onSuccess,
  onError
) => {
  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/queued-branches/${queuedBranch}`,
      type: "DELETE",
      success: body => {
        onSuccess();
      },
      error: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess();
        } else {
          onError();
        }
      },
    });
  });
};

export const fetchAllQueues = (queryParams, onSuccess, onError) => {
  const repo = queryParams.get("repo_uuid");
  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/queues/${repo}`,
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        onError();
      },
    });
  });
};

export const removeAttemptFromQueue = (attemptId, onSuccess, onError) => {
  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/attempts/${attemptId}`,
      type: "DELETE",
      success: body => {
        onSuccess();
      },
      error: (summary, response) => {
        if (response.statusCode === 404) {
          onSuccess();
        } else {
          onError();
        }
      },
    });
  });
};

export const fetchMoreOldAttempts = (queueId, cursor, onSuccess, onError) => {
  window.AP.require("proxyRequest", request => {
    request({
      url: `/bitbucket/queues/${queueId}/old-attempts/${cursor ? cursor : ""}`,
      success: body => {
        onSuccess(body);
      },
      error: (summary, response) => {
        onError();
      },
    });
  });
};

const API = {
  getRepository,
  createRepository,
  deleteRepository,
  getQueueForTargetBranch,
  getAttemptForSourceBranch,
  addBranchToQueue,
  removeBranchFromQueue,
  fetchAllQueues,
  removeAttemptFromQueue,
  fetchMoreOldAttempts,
};

export default API;
