import dedent from "dedent-js";

import {
  SET_REPOSITORY,
  SET_QUEUE_DETAILS,
  SET_QUEUES,
  REFRESHING,
  ADD_OLD_ATTEMPTS,
  SET_OLD_ATTEMPTS,
  OLD_ATTEMPTS_REFRESHING,
} from "./types";
import mtApi from "../apis/mergetastic";

export const getRepository = queryParams => async (dispatch, getState) => {
  dispatch(setRefreshing(true));
  mtApi.getRepository(
    queryParams,
    response => {
      dispatch(setRepository(response));
      dispatch(setRefreshing(false));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket. Try refreshing.
                 If error persists, contact support.`
        );
      });
    }
  );
};

export const createRepository = queryParams => async (dispatch, getState) => {
  dispatch(setRefreshing(true));
  mtApi.createRepository(
    queryParams,
    response => {
      dispatch(setRepository(response));
      dispatch(setRefreshing(false));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket. Try refreshing.
                 If error persists, contact support.`
        );
      });
    }
  );
};

export const deleteRepository = queryParams => async (dispatch, getState) => {
  dispatch(setRefreshing(true));
  mtApi.deleteRepository(
    queryParams,
    response => {
      dispatch(setRefreshing(false));
      dispatch(setRepository(null));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket. Try refreshing.
                 If error persists, contact support.`
        );
      });
    }
  );
};

export const setRepository = value => {
  return {
    type: SET_REPOSITORY,
    payload: value,
  };
};

export const setQueueDetails = value => {
  return {
    type: SET_QUEUE_DETAILS,
    payload: value,
  };
};

export const fetchBranchStatus = queryParams => async (dispatch, getState) => {
  dispatch(setRefreshing(true));
  mtApi.getQueueForTargetBranch(
    queryParams,
    response => {
      if (response.length === 0) {
        dispatch(setQueueDetails(null));
      } else {
        dispatch(setQueueDetails(response[0]));
      }
      dispatch(setRefreshing(false));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket and/or
                 Mergetastic. Try refreshing. If error persists, contact support.`
        );
      });
    }
  );
};

export const addBranchToQueue = (queryParams, queue) => async (
  dispatch,
  getState
) => {
  dispatch(setRefreshing(true));
  mtApi.addBranchToQueue(
    queryParams,
    queue,
    response => {
      // TODO: perhaps we don't have to make this unnecessary API call
      fetchBranchStatus(queryParams)(dispatch, getState);
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to add your branch to the merge queue. Please
                 try again after refreshing. If error persists, contact support.`
        );
      });
    }
  );
};

export const removeBranchFromQueue = (queryParams, queuedBranch) => async (
  dispatch,
  getState
) => {
  dispatch(setRefreshing(true));
  mtApi.removeBranchFromQueue(
    queryParams,
    queuedBranch,
    response => {
      // TODO: perhaps we don't have to make this unnecessary API call
      fetchBranchStatus(queryParams)(dispatch, getState);
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to remove your branch from the merge queue.
                 Please try again after refreshing. If error persists, contact
                 support.`
        );
      });
    }
  );
};

export const setRefreshing = value => {
  return {
    type: REFRESHING,
    payload: value,
  };
};

export const fetchAllQueues = (queryParams, showRefreshes = true) => async (
  dispatch,
  getState
) => {
  if (showRefreshes) dispatch(setRefreshing(true));
  mtApi.fetchAllQueues(
    queryParams,
    response => {
      dispatch(setRefreshing(false));
      dispatch(setQueues(response));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket and/or
                 Mergetastic. Try refreshing. If error persists, contact support.`
        );
      });
    }
  );
};

export const setQueues = value => {
  return {
    type: SET_QUEUES,
    payload: value,
  };
};

export const removeAttemptFromQueue = (queryParams, attemptId) => async (
  dispatch,
  getState
) => {
  dispatch(setRefreshing(true));
  mtApi.removeAttemptFromQueue(
    attemptId,
    response => {
      dispatch(fetchAllQueues(queryParams));
    },
    () => {
      dispatch(setRefreshing(false));
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket and/or
                 Mergetastic. Try refreshing. If error persists, contact support.`
        );
      });
    }
  );
};

export const fetchMoreOldAttempts = (
  queueId,
  restart = false,
  onSuccess = undefined,
  onError = undefined
) => async (dispatch, getState) => {
  const nextCursor = restart
    ? undefined
    : getState().queues[queueId].oldAttemptsNextCursor;

  mtApi.fetchMoreOldAttempts(
    queueId,
    nextCursor,
    response => {
      if (restart) {
        dispatch(setOldAttempts(queueId, response));
      } else {
        dispatch(addOldAttempts(queueId, response));
      }
      if (onSuccess) {
        onSuccess();
      }
    },
    () => {
      window.AP.require("messages", messages => {
        messages.error(
          "Mergetastic error",
          dedent`Mergetastic failed to communicate with Bitbucket and/or
                 Mergetastic. Try refreshing. If error persists, contact support.`
        );
      });
      if (onError) {
        onError();
      }
    }
  );
};

export const addOldAttempts = (queueId, response) => {
  return {
    type: ADD_OLD_ATTEMPTS,
    payload: {
      queueId: queueId,
      response: response,
    },
  };
};

export const setOldAttempts = (queueId, response) => {
  return {
    type: SET_OLD_ATTEMPTS,
    payload: {
      queueId: queueId,
      response: response,
    },
  };
};

export const setOldAttemptsRefreshing = (queueId, value) => {
  return {
    type: OLD_ATTEMPTS_REFRESHING,
    payload: value,
  };
};
