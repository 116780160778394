import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { hotjar } from "react-hotjar";

import PrOverviewPanel from "./PrOverviewPanel";
import RepoOverview from "./RepoOverview";
import RepoAdmin from "./RepoAdmin";

function App(props) {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID,
        process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
      );
    }
  }, []);

  return (
    <div>
      <BrowserRouter basename="/spa">
        <div>
          <Route path="/pr-overview-panel" exact component={PrOverviewPanel} />
          <Route path="/repo-overview" exact component={RepoOverview} />
          <Route path="/repo-admin" exact component={RepoAdmin} />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
