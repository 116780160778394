import { combineReducers } from "redux";
import repositoryReducer from "./repositoryReducer";
import queueDetailsReducer from "./queueDetailsReducer";
import queuesReducer from "./queuesReducer";
import refreshingReducer from "./refreshingReducer";

export default combineReducers({
  repository: repositoryReducer,
  queue: queueDetailsReducer,
  queues: queuesReducer,
  refreshing: refreshingReducer,
});
