import React from "react";
import Button from "@atlaskit/button/standard-button";

const ContactSupport = () => {
  return (
    <Button
      href="https://10xsoft.atlassian.net/servicedesk/customer/portal/3"
      target="_blank"
    >
      Contact Support
    </Button>
  );
};

export default ContactSupport;
