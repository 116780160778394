import React from "react";
import SectionMessage from "@atlaskit/section-message";
import { Code } from "@atlaskit/code";
import Panel from "@atlaskit/panel";

const RepoConfigError = ({ repository, hideDetails = false }) => {
  const details = () => {
    return (
      <>
        It seems like there's something wrong with your Mergetastic config file.
        When in doubt, take a look at{" "}
        <a
          href="https://mergetastic.com/docs/"
          target="_blank"
          rel="noreferrer"
        >
          the documentation
        </a>
        . The robots spit out this error message:
        <br />
        <br />
        <Code language="text" text={repository.config.error} />
        <br />
        <br />
        If you can't figure out how to fix your config file from this and the
        documentation, create a ticket via the{" "}
        <a
          href="https://10xsoft.atlassian.net/servicedesk/customer/portal/3"
          target="_blank"
          rel="noreferrer"
        >
          service desk
        </a>{" "}
        and we'll get our robot-whisperer to take a look and help you out.
      </>
    );
  };
  if (repository && repository.config && repository.config.error) {
    return (
      <span className="repoadmin-configerror">
        <SectionMessage
          title="There's a problem with your Mergetastic config"
          appearance="warning"
        >
          {hideDetails ? (
            <Panel header="Details">{details()}</Panel>
          ) : (
            details()
          )}
        </SectionMessage>
      </span>
    );
  } else {
    return null;
  }
};

export default RepoConfigError;
