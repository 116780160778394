import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Toggle from "@atlaskit/toggle";
import Tooltip from "@atlaskit/tooltip";
import PageHeader from "@atlaskit/page-header";
import { PageLayout, Banner, Content, Main } from "@atlaskit/page-layout";

import { getRepository, createRepository, deleteRepository } from "../actions";
import RepoConfigError from "./RepoConfigError";
import ContactSupport from "./ContactSupport";
import "./RepoAdmin.css";

const RepoAdmin = props => {
  const queryParams = new window.URLSearchParams(
    window.decodeURI(useLocation().search)
  );

  const tooltipContent = () => {
    switch (props.repository !== null) {
      case true:
        return "Disable Mergetastic";
      case false:
        return "Enable Mergetastic";
      default:
        return "Waiting for status";
    }
  };

  const { getRepository } = props;
  useEffect(() => {
    getRepository(queryParams);
  }, [getRepository]);

  const toggleEventHandler = () => {
    if (props.repository === null) {
      props.createRepository(queryParams);
    } else {
      props.deleteRepository(queryParams);
    }
  };

  return (
    <div className="repo-admin">
      <PageLayout>
        <Banner>
          <div className="header">
            <PageHeader actions={<ContactSupport />}>Mergetastic</PageHeader>
          </div>
        </Banner>
        <Content>
          <Main>
            <div className="content">
              <p>
                Mergetastic is a merge-queue system that will save you both time
                and money, while eliminating bad merges. Learn more at{" "}
                <a
                  href="https://mergetastic.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  mergetastic.com
                </a>
              </p>
              <div className="repo-admin-toggle-area">
                <Tooltip content={tooltipContent()}>
                  <label htmlFor="repo-admin-toggle">
                    Enable Mergetastic for this repository
                  </label>
                  <Toggle
                    id="repo-admin-toggle"
                    size="large"
                    onChange={toggleEventHandler}
                    isDisabled={props.refreshing === true}
                    isChecked={props.repository !== null}
                  />
                </Tooltip>
              </div>
              <RepoConfigError repository={props.repository} />
            </div>
          </Main>
        </Content>
      </PageLayout>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    repository: state.repository,
    refreshing: state.refreshing,
  };
};

export default connect(mapStateToProps, {
  getRepository,
  createRepository,
  deleteRepository,
})(RepoAdmin);
