import dedent from "dedent-js";
import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Tabs from "@atlaskit/tabs";
import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import PageHeader from "@atlaskit/page-header";
import {
  PageLayout,
  Banner,
  Content,
  TopNavigation,
  Main,
} from "@atlaskit/page-layout";
import EmptyState from "@atlaskit/empty-state";
import {
  getRepository,
  fetchAllQueues,
  removeAttemptFromQueue,
  fetchMoreOldAttempts,
} from "../actions";
import QueueOverview from "./QueueOverview";
import RepoConfigError from "./RepoConfigError";
import ContactSupport from "./ContactSupport";
import "./RepoOverview.css";

const createBreadcrumbs = queryParams => {
  return (
    <Breadcrumbs>
      <BreadcrumbsItem
        target="_top"
        href={queryParams.get("account_url")}
        text={queryParams.get("account_name")}
      />
      <BreadcrumbsItem
        target="_top"
        href={queryParams.get("project_url")}
        text={queryParams.get("project_name")}
      />
      <BreadcrumbsItem
        target="_top"
        href={queryParams.get("repo_url")}
        text={queryParams.get("repo_name")}
      />
    </Breadcrumbs>
  );
};

const NoQueueSetup = () => {
  const props = {
    header: "No queues found in the repository",
    description: dedent`To make use of Mergetastic, you should include a
                        mergetastic.yaml file containing queue details.`,
  };
  return (
    <span id="repo-overview--empty-state">
      <EmptyState {...props} />
    </span>
  );
};

const RepoOverview = props => {
  const queryParams = new window.URLSearchParams(
    window.decodeURI(useLocation().search)
  );
  const {
    repository,
    getRepository,
    fetchAllQueues,
    removeAttemptFromQueue,
    fetchMoreOldAttempts,
  } = props;

  useEffect(() => {
    getRepository(queryParams);
  }, [getRepository]);

  // TODO: Consider re-introducing this later.
  // useInterval(() => {
  //   if (enabled) fetchAllQueues(queryParams, false);
  // }, 10000);
  // And call refresh for the first time just so the page loads with the correct
  // value. This time, let the spinner go.
  useEffect(() => {
    if (repository) fetchAllQueues(queryParams);
  }, [repository, fetchAllQueues]);

  const mainContent = () => {
    const tabs = _.map(Object.values(props.queues || {}), queue => {
      return {
        label: queue.target_branch,
        content: (
          <QueueOverview
            queryParams={queryParams}
            removeAttemptFromQueue={removeAttemptFromQueue}
            queue={queue}
            fetchMoreOldAttempts={fetchMoreOldAttempts}
          />
        ),
      };
    });

    if (props.refreshing) {
      return <QueueOverview loading={props.refreshing} />;
    } else if (
      props.queues === undefined ||
      props.queues === null ||
      Object.keys(props.queues).length === 0
    ) {
      return <NoQueueSetup />;
    } else if (Object.keys(props.queues).length === 1) {
      return (
        <QueueOverview
          queryParams={queryParams}
          removeAttemptFromQueue={removeAttemptFromQueue}
          queue={Object.values(props.queues)[0]}
          fetchMoreOldAttempts={fetchMoreOldAttempts}
        />
      );
    } else {
      return <Tabs tabs={tabs} />;
    }
  };

  return (
    <div className="repo-overview">
      <PageLayout>
        <Banner>
          <div className="header">
            <PageHeader
              breadcrumbs={createBreadcrumbs(queryParams)}
              actions={<ContactSupport />}
            >
              Mergetastic
            </PageHeader>
          </div>
        </Banner>
        <TopNavigation />
        <Content>
          <Main>
            <div className="error-banner">
              <RepoConfigError repository={repository} />
            </div>
            {mainContent()}
          </Main>
        </Content>
      </PageLayout>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    repository: state.repository,
    queues: state.queues,
    refreshing: state.refreshing,
  };
};

export default connect(mapStateToProps, {
  getRepository,
  fetchAllQueues,
  removeAttemptFromQueue,
  fetchMoreOldAttempts,
})(RepoOverview);
