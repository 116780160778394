import { REFRESHING } from "../actions/types";

const refreshingReducer = (state = true, action) => {
  switch (action.type) {
    case REFRESHING:
      return action.payload;
    default:
      return state;
  }
};

export default refreshingReducer;
