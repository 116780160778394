import React, { useState, useEffect } from "react";
import _ from "lodash";
import { LoadingButton } from "@atlaskit/button";
import DynamicTable from "@atlaskit/dynamic-table";
import UnlockFilledIcon from "@atlaskit/icon/glyph/unlock-filled";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import { G300, N100, R400 } from "@atlaskit/theme/colors";
import Spinner from "@atlaskit/spinner";
import Tooltip from "@atlaskit/tooltip";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import InlineDialog from "@atlaskit/inline-dialog";
import TimeAgo from "timeago-react";
import "./QueueOverview.css";

const AttemptMember = ({ qb }) => {
  return (
    <div className="attempt-member">
      <Tooltip content={qb.pr.title}>
        <a href={qb.pr.links.html.href} target="_top" className="pr-link">
          PR #{qb.pr.id}
        </a>
      </Tooltip>
    </div>
  );
};

const AttemptMembers = ({ queue, attempt }) => {
  const qbs = attempt.queued_branch_attempts.map(qba => qba.queued_branch);
  return (
    <>
      {qbs.map(qb => (
        <AttemptMember qb={qb} key={`${attempt.id}/${qb.id}`} />
      ))}
    </>
  );
};

const OpenAttemptMembers = ({ queuedBranches }) => {
  return (
    <>
      {queuedBranches.map(qb => (
        <AttemptMember qb={qb} key={`open-attempt/${qb.id}`} />
      ))}
    </>
  );
};

const CommitStatusIcon = ({ state }) => {
  switch (state) {
    case "SUCCESSFUL":
      return (
        <CheckCircleIcon primaryColor={G300} size="small" label="Successful" />
      );
    case "FAILED":
      return (
        <CrossCircleIcon primaryColor={R400} size="small" label="Failed" />
      );
    case "INPROGRESS":
      return <Spinner size="small" />;
    case "STOPPED":
      return (
        <CrossCircleIcon primaryColor={R400} size="small" label="Stopped" />
      );
    default:
      return null;
  }
};

const CommitStatuses = ({ attempt }) => {
  if (!attempt.commit_statuses || attempt.commit_statuses.length === 0) {
    return (
      <>
        <Spinner size="medium" /> Waiting for commit status(es) to be posted
      </>
    );
    return <p>No commit statuses found</p>;
  } else {
    return (
      <>
        {attempt.commit_statuses.map(cs => (
          <div className="commitstatus" key={`${attempt.id}/${cs.key}`}>
            <span title={cs.state.toLowerCase()}>
              <CommitStatusIcon state={cs.state} title={cs.state} /> &nbsp;
            </span>
            <a href={cs.url} target="_top" title={cs.description}>
              {cs.name || cs.key}
            </a>
            <TimeAgo title={new Date(cs.updated_ts)} datetime={cs.updated_ts} />
          </div>
        ))}
      </>
    );
  }
};

const AttemptStatus = ({ attempt, queue }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const showDetails = () => setDetailsVisible(true);
  const hideDetails = () => setDetailsVisible(false);

  const runningAttempt = (queue.running_attempt || {}).id;
  const status = attempt.id === runningAttempt ? "RUNNING" : attempt.status;
  return (
    <InlineDialog
      onClose={hideDetails}
      content={<CommitStatuses attempt={attempt} />}
      isOpen={detailsVisible}
    >
      {attempt.head ? (
        <a
          href={attempt.head.links.html.href}
          target="_top"
          onMouseOver={showDetails}
          className="attempt-status"
        >
          {status}
        </a>
      ) : (
        // The attempt hasn't been run yet
        <span className="attempt-status">{status}</span>
      )}
    </InlineDialog>
  );
};

const EmptyQueue = () => {
  return <span id="queue-overview--empty-state">The queue is empty</span>;
};

const CancelAttempt = ({ queryParams, removeAttemptFromQueue, attempt }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <Tooltip content="Cancel Attempt">
        <span className="cancel-attempt" onClick={open}>
          <TrashIcon size="small" />
        </span>
      </Tooltip>

      <ModalTransition>
        {isOpen && (
          <Modal
            actions={[
              {
                text: "Cancel Attempt",
                onClick: () => removeAttemptFromQueue(queryParams, attempt.id),
              },
              { text: "Nevermind", onClick: close },
            ]}
            onClose={close}
            heading="Are you sure you want to cancel this attempt?"
            appearance="warning"
          >
            Attempt cancellation will remove all its member pull-requests from
            the queue? If you just want to remove a specific PR from the queue,
            you can do so on that PR's page.
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

const OpenAttemptCancel = () => {
  const [helpVisible, setHelpVisible] = useState(false);
  const showHelp = () => setHelpVisible(true);
  const hideHelp = () => setHelpVisible(false);
  const help = (
    <span>
      <h4>Cannot cancel open attempt</h4>
      <p>
        This is an "open attempt". This means that it collects any queued Pull
        Requests while other attempts remain in the queue. Once all other
        attempts are run, this attempt will be closed and run.
      </p>
      <p>
        If you wish to remove any Pull Requests from the queue, you can do so
        from its page.
      </p>
    </span>
  );

  return (
    <InlineDialog onClose={hideHelp} content={help} isOpen={helpVisible}>
      <span onMouseOver={showHelp} onMouseOut={hideHelp}>
        <UnlockFilledIcon size="small" primaryColor={N100} />
      </span>
    </InlineDialog>
  );
};

const ActiveAttempts = ({
  queue,
  queryParams,
  removeAttemptFromQueue,
  loading,
}) => {
  const attempts = (queue || {}).attempts || [];
  const queuedBranches = (queue || {}).queued_branches || [];
  const head = {
    cells: [
      {
        key: "members",
        content: "Members",
        isSortable: false,
        shouldTruncate: true,
        width: 50,
      },
      {
        key: "created",
        content: "Created",
        isSortable: true,
      },
      {
        key: "status",
        content: "Status",
        isSortable: false,
      },
      {
        key: "cancel",
      },
    ],
  };

  let attemptRows = attempts.map(attempt => ({
    key: `attempt-${attempt.id}`,
    cells: [
      {
        key: `attempt-members-${attempt.id}`,
        content: <AttemptMembers queue={queue} attempt={attempt} />,
      },
      {
        key: `attempt-created-${attempt.id}`,
        content: (
          <Tooltip content={String(new Date(attempt.created_ts))}>
            <TimeAgo datetime={attempt.created_ts} />
          </Tooltip>
        ),
      },
      {
        key: `attempt-status-${attempt.id}`,
        content: <AttemptStatus attempt={attempt} queue={queue} />,
      },
      {
        key: `attempt-cancel-${attempt.id}`,
        content: (
          <CancelAttempt
            queryParams={queryParams}
            removeAttemptFromQueue={removeAttemptFromQueue}
            attempt={attempt}
          />
        ),
      },
    ],
  }));
  const branchesNotInAttemptSet = new Set(queuedBranches.map(qb => qb.id));
  for (const attempt of attempts) {
    for (const qba of attempt.queued_branch_attempts) {
      branchesNotInAttemptSet.delete(qba.queued_branch.id);
    }
  }
  const branchesNotInAttempt = queuedBranches.filter(qb =>
    branchesNotInAttemptSet.has(qb.id)
  );
  if (branchesNotInAttempt.length > 0) {
    attemptRows.push({
      key: "open-attempt",
      cells: [
        {
          key: "open-attempt-members",
          content: <OpenAttemptMembers queuedBranches={branchesNotInAttempt} />,
        },
        {
          key: "open-attempt-created",
          content: "",
        },
        {
          key: "open-attempt-status",
          content: <p className="attempt-status">PENDING</p>,
        },
        {
          key: "open-attempt-cancel",
          content: <OpenAttemptCancel />,
        },
      ],
    });
  }

  if (attemptRows.length === 0 && !loading) {
    return <EmptyQueue />;
  } else {
    return (
      <DynamicTable
        head={head}
        rows={attemptRows}
        isLoading={loading}
        testId={"active-attempts-table"}
      />
    );
  }
};

const HistoricalAttempts = ({ queue, fetchMoreOldAttempts }) => {
  const [refreshing, setRefreshing] = useState(false);
  useEffect(() => {
    fetchMoreOldAttempts(queue.id, true);
  }, [fetchMoreOldAttempts, queue.id]);

  const attempts = queue.oldAttempts || [];

  if (attempts.length === 0) {
    return "";
  }

  const head = {
    cells: [
      {
        key: "members",
        content: "Members",
        isSortable: false,
        shouldTruncate: true,
        width: 50,
      },
      {
        key: "created",
        content: "Created",
        isSortable: false,
      },
      {
        key: "status",
        content: "Status",
        isSortable: false,
      },
    ],
  };
  const attemptRows = attempts.map(attempt => ({
    key: `attempt-${attempt.id}`,
    cells: [
      {
        key: `attempt-members-${attempt.id}`,
        content: <AttemptMembers queue={queue} attempt={attempt} />,
      },
      {
        key: `attempt-created-${attempt.id}`,
        content: (
          <Tooltip content={String(new Date(attempt.created_ts))}>
            <TimeAgo datetime={attempt.created_ts} />
          </Tooltip>
        ),
      },
      {
        key: `attempt-status-${attempt.id}`,
        content: <AttemptStatus attempt={attempt} queue={queue} />,
      },
    ],
  }));
  const loadMoreClickHandler = (e, analyticsEvent) => {
    setRefreshing(true);
    fetchMoreOldAttempts(
      queue.id,
      false,
      () => setRefreshing(false),
      () => setRefreshing(false)
    );
  };
  return (
    <div id="historical-attempts">
      <h3>Historical Attempts</h3>
      <DynamicTable
        head={head}
        rows={attemptRows}
        testId={"old-attempts-table"}
      />
      {queue.oldAttemptsNextCursor ? (
        <LoadingButton onClick={loadMoreClickHandler} isLoading={refreshing}>
          Load more
        </LoadingButton>
      ) : (
        ""
      )}
    </div>
  );
};

const QueueOverview = ({
  queue,
  queryParams,
  removeAttemptFromQueue,
  fetchMoreOldAttempts,
  loading = false,
}) => {
  return (
    <div className="queue-overview">
      <ActiveAttempts
        queue={queue}
        queryParams={queryParams}
        removeAttemptFromQueue={removeAttemptFromQueue}
        loading={loading}
      />
      {loading ? (
        // If the whole thing is loading, no point trying to get historical data.
        // We don't even know about the queue details.
        ""
      ) : (
        <HistoricalAttempts
          queue={queue}
          fetchMoreOldAttempts={fetchMoreOldAttempts}
        />
      )}
    </div>
  );
};

export default QueueOverview;
